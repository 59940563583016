html,body{
    width: 100% !important;
    overflow-x: hidden !important;
    /* overflow-y: auto !important; */
    height: auto;
    /* height: 100vh; */
    /* touch-action: none !important;
    -ms-touch-action: none !important; */
}

#root{
    width: 100% !important;
    overflow-x: hidden !important;
    /* overflow-y: auto !important; */
    /* height: 100vh; */
    /* touch-action: none !important;
    -ms-touch-action: none !important; */
}

.flex{
    display: flex;
    justify-content: center;
    align-items: center;
}