:root {
  --primary-cl:#EB6502;
  --secondary-cl:#2B2A29;
  --tertiary-cl:#FFDCB6;
  --black-cl:#1D1C1A;
  --grey-white-cl:#EFEFEF;
  --lightgrey:#F6F6F6;
  --ff:'raleway_regular';
  --para-fs:14px;
  --line-height:25px;
}

@media(max-width:968px){
  :root{
    --para-fs:13px;
    --line-height:22px;
  }
}

/* Raleway */

@font-face {
  font-family: 'raleway_black'; 
  src: url('./assets/fonts/Raleway/Raleway-Black.woff') 
}
@font-face {
  font-family: 'raleway_bold'; 
  src: url('./assets/fonts/Raleway/Raleway-ExtraBold.woff') 
}
@font-face {
  font-family: 'raleway_light'; 
  src: url('./assets/fonts/Raleway/Raleway-Light.woff') 
}
@font-face {
  font-family: 'raleway_medium'; 
  src: url('./assets/fonts/Raleway/Raleway-Medium.woff') 
}
@font-face {
  font-family: 'raleway_regular'; 
  src: url('./assets/fonts/Raleway/Raleway-Regular.woff') 
}
@font-face {
  font-family: 'raleway_semibold'; 
  src: url('./assets/fonts/Raleway/Raleway-SemiBold.woff')
}
@font-face {
  font-family: 'raleway_thin'; 
  src: url('./assets/fonts/Raleway/Raleway-Thin.woff')
}

/* Wensley */

@font-face {
  font-family: 'wensley_bold'; 
  src: url('./assets/fonts/wensley/Wensley-Bold.woff') 
}
@font-face {
  font-family: 'wensley_light'; 
  src: url('./assets/fonts/wensley/Wensley-Light.woff') 
}
@font-face {
  font-family: 'wensley_regular'; 
  src: url('./assets/fonts/wensley/Wensley-Regular.woff') 
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
